import React, { useRef, useEffect, useState } from 'react';
import { Fade } from 'react-reveal';
import Hero from './Hero';

const Contact = () => {
  const contactRef = useRef(null);
  const [scrolledDown, setScrolledDown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        // User has scrolled down
        setScrolledDown(true);
      } else {
        // User is at the top
        setScrolledDown(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <Hero />
      <section
        className={`bg-gray-100 py-16 ${scrolledDown ? '' : 'large-margin'}`}
      >        <div ref={contactRef} id="contact" className="container mx-auto">
          <h2 className="text-3xl font-semibold mb-8 text-center">Contact Me</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <Fade top>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-2xl font-semibold mb-4">Get in Touch</h3>
                <p className="text-gray-700">
                  If you'd like to reach out to me or discuss a project, feel free to contact me using the information below:
                </p>
                <div className="mt-6">
                  <p className="text-gray-800 font-semibold">Email:</p>
                  <p className="text-indigo-600">sharafatt6@mail.com</p>
                </div>
                <div className="mt-4">
                  <p className="text-gray-800 font-semibold">Phone:</p>
                  <p className="text-indigo-600">+92-323-3910-824</p>
                </div>
                <div className="mt-6">
                  <p className="text-gray-800 font-semibold">Address:</p>
                  <p className="text-gray-700">123 Main Street, City Name</p>
                </div>
                <div className="mt-4">
                  <p className="text-gray-800 font-semibold">LinkedIn:</p>
                  <a
                    href="https://www.linkedin.com/in/your-linkedin-profile"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-indigo-600 hover:underline"
                  >
                    linkedin.com/in/your-linkedin-profile
                  </a>
                </div>
              </div>
            </Fade>
            <Fade bottom>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-2xl font-semibold mb-4">Send a Message</h3>
                <form>
                  <div className="mb-4">
                    <label htmlFor="name" className="block text-gray-800 font-semibold">Name</label>
                    <input type="text" id="name" name="name" className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-indigo-500" />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="email" className="block text-gray-800 font-semibold">Email</label>
                    <input type="email" id="email" name="email" className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-indigo-500" />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="message" className="block text-gray-800 font-semibold">Message</label>
                    <textarea id="message" name="message" rows="4" className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-indigo-500"></textarea>
                  </div>
                  <button type="submit" className="bg-indigo-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-indigo-600 transition duration-300 ease-in-out">Send Message</button>
                </form>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;

