import Home from "./frontend/Home";

function App() {
  return (
    <>
     <Home/>
    </>
  );
}

export default App;

