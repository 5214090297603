import React from 'react';

const Resume = () => {
  const resumeLink =
    'https://drive.google.com/file/d/1wcdgqNaNZxFuAEXsSl5ENaLLf97UiBcf/view';

  return (
    <section className="bg-gray-100 py-16 mt-10">
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold mb-6">Resume</h2>
        <div className="mb-8">
          <a
            href={resumeLink}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
          >
            Download Resume
          </a>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <iframe
            src={resumeLink.replace('/view', '/preview')}
            title="Resume"
            width="100%"
            height="800px"
            style={{ border: 'none' }}
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Resume;
