import React from 'react'
import Navbar from './partials/Navbar'
import Hero from './components/Hero'
import About from './components/About'
import Services from './components/Services'
import Projects from './components/Projects'
import Contact from './components/Contact'
import Footer from './components/Footer' 
// import Dashboard from '../backend/Dashboard'
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';
import Resume from './components/Resume'
import Login from './components/Login'

const Home = () => {
  return (
    <>
        <Navbar />
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/login" element={<Login/>} />
          {/* <Route path='/admin/dashboard' element={<Dashboard/>}/> */}
        </Routes>
        <Footer />
    </>
  )
}

export default Home
