import React from 'react';
import aboutImage from '../../images/mohammad-rahmani-1bNQVGzuy0U-unsplash.jpg';
import { Fade } from 'react-reveal';

const About = () => {
  return (
    <section className="bg-gray-900 text-white py-16 mt-10">
      <div className="container mx-auto flex flex-col md:flex-row items-center">
        <div className="lg:w-1/2 lg:pr-8 md:pr-4 flex-shrink-0">
          <Fade right>
          <img
            src={aboutImage} // Update the path to your about image
            alt="About Me"
            className="object-cover object-center rounded-lg"
          />
          </Fade>
        </div>
        <Fade left>
        <div className="lg:w-1/2 mt-8 md:mt-0">
          <h2 className="text-3xl font-semibold mb-6">About Me</h2>
          <p className="text-gray-400 leading-relaxed">
            Hi, I'm Sharafat Ali, a passionate web developer with experience in creating responsive and user-friendly websites. I take pride in delivering clean code and exceptional user experiences. My goal is to turn your ideas into reality and create web applications that stand out.
          </p>
        </div>
        </Fade>
      </div>
    </section>
  );
};

export default About;


