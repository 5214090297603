import React from 'react';
import autowise from '../../images/Screenshot 2023-10-08 201344.png'
import softech from '../../images/Screenshot 2023-08-06 122735.png'
import opencart from '../../images/Screenshot 2023-10-08 203351.png'
import { Fade } from 'react-reveal';

const ProjectCard = ({ title, description, image, link }) => {
    return (
        <Fade left>
        <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden hover:bg-indigo-800 transition duration-300 ease-in-out transform hover:-translate-y-1">
            <img src={image} alt={title} className="w-full h-48 object-cover object-center" />
            <div className="p-6">
                <h3 className="text-xl font-semibold mb-2 text-white">{title}</h3>
                <p className="text-gray-400 mb-4">{description}</p>
                <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-indigo-500 hover:text-indigo-300 hover:underline"
                >
                    View Project
                </a>
            </div>
        </div>
        </Fade>
    );
};

const projectsData = [
    {
        title: 'E-Commerce Platform',
        description: 'I had the privilege of contributing to a comprehensive E-Commerce platform, delivering a seamless shopping experience to customers and robust management tools for business owners. This project encompassed a wide array of features and functionalities, including:',
        image: softech,
        link: 'https://softechit.online',
    },
    {
        title: 'Autowise: Comprehensive E-Commerce Platform',
        description: 'In the "Autowise" project, I played a pivotal role in developing a comprehensive E-Commerce platform designed to provide customers with a seamless shopping experience and empower business owners with robust management tools. This multifaceted project encompassed a wide range of features and functionalities, including:',
        image: autowise,
        link: 'https://autowise.fi',
    },
    {
        title: 'Open Cart Integration',
        description: 'For the "Open Cart" project, my contributions included the implementation of a robust Bulk Product Upload System. This involved creating a seamless PHP API for data handling and developing a user-friendly React.js frontend for efficient management.',
        image: opencart,
        link: 'https://securegrades.com/',
    }
    // Add more project objects
];

const Projects = () => {
    return (
        <section className="bg-gray-900 py-16 mt-10">
            <div className="container mx-auto">
                <h2 className="text-3xl font-semibold mb-6 text-center text-white">Projects</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {projectsData.map((project, index) => (
                        <ProjectCard key={index} {...project} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Projects;
