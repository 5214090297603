import React from 'react';
import heroImage from '../../images/android-chrome-512x512.png';
import { Fade } from 'react-reveal';
import About from './About';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div id='home'>
      <section className="text-gray-400 bg-gray-900 body-font relative">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <Fade bottom>
              <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
                Hi, I'm Sharafat Ali
                <br className="hidden lg:inline-block" />
                <span className="text-indigo-500 animate-text">
                  PHP, JavaScript Developer
                </span>
              </h1>
            </Fade>
            <Fade bottom>
              <p className="mb-8 leading-relaxed text-gray-500">
                I build web applications with a passion for clean code and exceptional user experiences. Let's turn your ideas into reality.
              </p>
            </Fade>
            <Fade bottom>
              <div className="flex justify-center">
                <a
                  href="#contact"
                  className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg mr-4"
                >
                  Get In Touch
                </a>
                <Link
                  to="/resume" // Replace with the actual path to your resume
                  className="inline-flex text-white bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 rounded text-lg"
                >
                  View Resume
                </Link>
              </div>
            </Fade>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 relative">
            <Fade top>
              <div className="relative overflow-hidden">
                <img
                  alt="hero"
                  src={heroImage}
                  className="object-cover object-center rounded transform hover:scale-105 transition-transform duration-300"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-70"></div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      {/* <About/> */}
    </div>
  );
};

export default Hero;
