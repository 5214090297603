import React from 'react';
import frontend from '../../images/frontend-development-tools.png';
import backend from '../../images/img01.jpg'
import wordpress from '../../images/photo-1566207474742-de921626ad0c.jpeg'
import { Fade } from 'react-reveal';

const ServiceCard = ({ title, description, image }) => {
  return (
    <>
    <Fade right>

    <div className="bg-gray-800 p-6 rounded-lg shadow-lg transform transition-transform hover:scale-105 hover:shadow-xl">
      <img src={image} alt={title} className="mx-auto mb-4" />
      <h3 className="text-xl font-semibold mb-2 text-white">{title}</h3>
      <p className="text-gray-400">{description}</p>
    </div>
    </Fade>
    </>
  );
};

const servicesData = [
  {
    title: 'Frontend Development',
    description: 'Expertise in React, Vue.js, and other frontend technologies.',
    image: frontend,
  },
  {
    title: 'Backend Development',
    description: 'Proficient in PHP, Laravel, CodeIgniter, and more for backend development.',
    image: backend,
  },
  {
    title: 'Wordpress',
    description: 'Proficient in Wordpress with front-end and back-end.',
    image: wordpress,
  },
  // Add more service objects for other skills
];

const Services = () => {
  return (
    <section className="bg-gray-900 py-16 mt-10">
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold mb-6 text-center text-white">Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {servicesData.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
