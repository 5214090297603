import React from 'react';
import logo from '../../icons/favicon.ico';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();

  // Define a function to determine if a NavLink should be considered active
  const isNavLinkActive = (path) => {
    return location.pathname === path ? 'text-indigo-500 bg-gray-800' : '';
  };

  return (
    <div>
      <header className="bg-gray-900 text-white fixed top-0 w-full z-50 mb-6">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <Link to="/" className="flex title-font font-medium items-center text-white mb-4 md:mb-0">
            <img
              src={logo} // Update the path to point to your image file
              alt="Your Image Alt Text"
              className="w-10 h-10 rounded-full"
            />
            <span className="ml-3 text-xl">Sharafat Ali</span>
          </Link>
          <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
            <Link to="/" className={`mr-5 hover:text-indigo-500 hover:bg-gray-800 transition duration-300 ease-in-out rounded-full py-2 px-4 ${isNavLinkActive('/')}`}>Home</Link>
            <Link to="/about" className={`mr-5 hover:text-indigo-500 hover:bg-gray-800 transition duration-300 ease-in-out rounded-full py-2 px-4 ${isNavLinkActive('/about')}`}>About</Link>
            <Link to="/services" className={`mr-5 hover:text-indigo-500 hover:bg-gray-800 transition duration-300 ease-in-out rounded-full py-2 px-4 ${isNavLinkActive('/services')}`}>Services</Link>
            <Link to="/projects" className={`mr-5 hover:text-indigo-500 hover:bg-gray-800 transition duration-300 ease-in-out rounded-full py-2 px-4 ${isNavLinkActive('/projects')}`}>Projects</Link>
            <Link to="/contact" className={`mr-5 hover:text-indigo-500 hover:bg-gray-800 transition duration-300 ease-in-out rounded-full py-2 px-4 ${isNavLinkActive('/contact')}`}>Contact</Link>
          </nav>
          <Link to='/login' className="inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0">Login
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
      </header>
    </div>
  );
}

export default Navbar;
