import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLinkedin,
  faGithub,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();

  // Define a function to determine if a NavLink should be considered active
  const isNavLinkActive = (path) => {
    return location.pathname === path ? 'text-indigo-500 hover:text-gray-300 mr-4' : 'text-indigo-500 hover:text-gray-300';
  };

  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto flex flex-col items-center">
        <div className="flex mb-4">
          <Link to="/" className={`mr-4 ${isNavLinkActive('/')}`}>
            Home
          </Link>
          <Link to="/about" className={`mr-4 ${isNavLinkActive('/about')}`}>
            About
          </Link>
          <Link to="/projects" className={`mr-4 ${isNavLinkActive('/projects')}`}>
            Projects
          </Link>
          <Link to="/contact" className={`${isNavLinkActive('/contact')}`}>
            Contact
          </Link>
        </div>
        <div className="text-sm text-gray-500">
          &copy; {new Date().getFullYear()} Your Name. All rights reserved.
        </div>
        <div className="mt-4">
          <Link
            to="#"
            className="text-indigo-500 hover:text-gray-300 mr-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </Link>
          <Link
            to="#"
            className="text-indigo-500 hover:text-gray-300 mr-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faGithub} />
          </Link>
          <Link
            to="#"
            className="text-indigo-500 hover:text-gray-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </Link>
        </div>
        <div className="mt-4 text-xs text-gray-500">
          Created by Sharafat Ali
        </div>
      </div>
    </footer>
  );
};

export default Footer;
